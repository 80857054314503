import apiClient from './api'

export default {
    async get (id) {
        return await apiClient.get(`/cms/transactions/${id}`)
    },
    async getAll (params = null) {
        return await apiClient.get('/cms/transactions', { params })
    },
    async paginate (params) {
        params.paginate = true
        params.perPage = params.itemsPerPage
        const result = await apiClient.get('/cms/transactions', { params })
        result.data.total = Number(result.data.total)
        return result
    },
    async cancel (id) {
        return await apiClient.post(`/cms/transactions/${id}/cancel`)
    },
}