<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn color="purple" class="font-weight-bold mr-0" :to="`/transactions/add`">Add item</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-title>
                        <v-row class="py-2">
                            <v-col cols="12" md="9">
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Filter') }} <v-icon right>mdi-filter</v-icon>
                                </v-btn>
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Export') }} <v-icon right>mdi-file-table</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" class="text-md-end">
                                <v-text-field hide-details flat filled outlined solo dense single-line
                                    append-icon="mdi-magnify" :label="$t('Search')" v-model="search"
                                    @keyup.enter="onSearch"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                        <v-data-table :items="items" :headers="headers" :options.sync="options"
                            :server-items-length="totalItems" :loading="loading" class="elevation-1">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{ row.item.id }}</td>
                                    <td>
                                        <router-link :to="`/companies/edit/${row.item.company?.id}`">
                                            {{ row.item.company?.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="`/locations/edit/${row.item.location?.id}`">
                                            {{ row.item.location?.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="`/users/edit/${row.item.user?.id}`">
                                            {{ row.item.user?.username }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.item.quality }}</td>
                                    <td>{{ row.item.amount }}</td>
                                    <td>{{ row.item.ticket }}</td>
                                    <td>
                                        <a v-if="row.item?.urls?.input" :href="row.item.urls.input" target="_blank"
                                            :download="row.item.input_name">{{ row.item.input_name }}</a>
                                    </td>
                                    <td>
                                        <a v-if="row.item?.urls?.output" :href="row.item.urls.output"
                                            target="_blank">Open</a>
                                    </td>
                                    <td>
                                        <v-chip small :color="statusColor(row.item.status)" class="font-weight-bold">
                                            {{ $t(row.item.status.name) }}
                                        </v-chip>
                                    </td>
                                    <td>{{ getDuration(row.item) }}</td>
                                    <td>{{ row.item.created_at | utcToLocal }}</td>
                                    <td>{{ row.item.status_message }}</td>
                                    <td>
                                        <v-btn small color="error" class="mx-2" @click="onCancel(row.item)"
                                            v-if="row.item.status.alias === 'processing'">
                                            <v-icon>mdi-stop-circle</v-icon>
                                        </v-btn>
                                        <v-btn small color="blue" class="mx-2" @click="onViewSteps(row.item)">
                                            <v-icon>mdi-clipboard-list</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-toolbar dark color="dark">
                    <v-toolbar-title>{{ $t('Transaction Steps') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-left">{{ $t('Step') }}</th>
                                <th class="text-left">{{ $t('Date') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in dialogItems">
                                <td>{{ item.type.name }}</td>
                                <td>{{ item.date | utcToLocal('L LTS') }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import transactionService from '@/services/transactionService'
import dayjs from "dayjs";

export default {
    data() {
        return {
            items: [],
            totalItems: 0,
            loading: true,
            options: { sortBy: ['id'], sortDesc: ['true'] },
            search: null,
            headers: [
                { text: '#', value: 'id' },
                { text: 'Company', value: 'company_name' },
                { text: 'Location', value: 'location_name' },
                { text: 'User', value: 'username' },
                { text: 'Quality', value: 'quality' },
                { text: 'Amount', value: 'amount' },
                { text: 'Ticket', value: 'ticket' },
                { text: 'Input', value: 'input', sortable: false },
                { text: 'Output', value: 'output', sortable: false },
                { text: 'Status', value: 'status' },
                { text: 'Duration', value: 'duration' },
                { text: 'Created', value: 'created_at' },
                { text: 'Status Message', value: 'status_message', sortable: false },
                { text: 'Action', sortable: false },
            ],
            dialog: false,
            dialogItems: []
        }
    },
    watch: {
        options: {
            handler(curr, prev) {
                const watchProps = ['page', 'itemsPerPage', 'sortBy', 'sortDesc']
                for (let i = 0; i < watchProps.length; i++) {
                    const key = watchProps[i]
                    if (prev[key] !== curr[key]) {
                        return this.getTransactions()
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        async getTransactions() {
            this.loading = true
            try {
                const result = await transactionService.paginate({
                    ...this.options,
                    search: this.search,
                    include_company: true,
                    include_location: true,
                    include_user: true,
                    include_steps: true,
                })
                this.items = result.data.data
                this.totalItems = result.data.total
                this.options.page = result.data.page

            } catch (error) {
                this.$dialog.message.error(error.message)
            } finally {
                this.loading = false
            }
        },
        onSearch() {
            this.getTransactions()
        },
        async onCancel(item) {
            const confirm = await this.$dialog.error({
                title: this.$t('Warning'),
                text: this.$t('Are you sure you want to cancel this item?'),
            })
            if (confirm) {
                try {
                    await transactionService.cancel(item.id)
                    this.getTransactions()
                    this.$dialog.message.success(this.$t('Success'))
                } catch (error) {
                    this.$dialog.message.error(error.message)
                }
            }
        },
        async onViewSteps(item) {
            try {
                const result = await transactionService.get(item.id)
                this.dialog = true
                this.dialogItems = [...result.data.steps].sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        statusColor(status) {
            const colors = {
                pending: 'warning',
                processing: 'blue',
                completed: 'success',
                canceled: '',
                failed: 'error'
            }
            return colors[status.alias]
        },
        getDuration(item) {
            if (item.steps && item.steps.length > 0) {
                const steps = [...item.steps].sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                const first = steps[0]
                const last = steps[steps.length - 1]
                return dayjs(last.date).diff(first.date, 'seconds') + ' sec.'
            }
            return ''
        }
    },
}
</script>
